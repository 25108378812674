import React from "react";
import { graphql, Link } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout";
import Card from "../components/card";
import CTA from "../components/cta-bar";
import PhotoGalleryComponent from "../components/photo-gallery-component";
import ReviewSliderFullWidth from "../components/reviews-slider-fullwidth";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { OPEN_CONTACT_MODAL } from "../consts";
import { useTextReplacement } from "../hooks/useTextReplacement";
import Popup from "reactjs-popup";
import ContactForm from "../components/estimate-form";
import { X, ChevronRight } from "react-feather"; 

import { AreasServed } from "../components/areas-served";
import { domToReact } from "html-react-parser";

import { TestimonialSection } from "../components/testimonial_video";
import ReviewTilesComponent from "../components/ReviewTilesComponent";
import FAQ from "../components/FAQ";
import ServiceHeroFullBGService from "../components/ServiceHeroFullBGService";
import WhyUsSection from "../components/WhyUsSection";
import ProcessSection from "../components/OurProcess";
import Testimonials11 from "../components/Testimonials11";
import { ArrowRight, Star } from "lucide-react";
import Button from "../components/atoms/Button";
import ProductGalleryComponent from "../components/product-gallery";

const ServiceTemplate = (props) => {
  const pageData = props.data.contentfulService;
  const { citiesList } = props.data;
  const { testimonials: testimonial_video_posts } = pageData.testimonialSection || { testimonials: [] };
  const { showAreasServed } = pageData;

  const options = {
    replace: ({ attribs, children, name, type, ...theRest }) => {
      if (!attribs) return;
      if (type == "tag" && name == "a" && attribs.class.includes(OPEN_CONTACT_MODAL)) {
        const ATagButton = ({ onClick }) => {
          return (
            <a
              style={{ borderWidth: "0" }}
              href={attribs.href}
              className="border-radius button-style-primary button-style-white-outline border-0 cursor-pointer primary cta-button"
              onClick={(e) => {
                e.preventDefault();
                onClick(e);
              }}
            >
              {domToReact(children, options)}
            </a>
          );
        };
        return (
          <Popup trigger={<ATagButton />} modal closeOnDocumentClick>
            {(close) => (
              <div className="p-2 block">
                <button
                  style={{ borderWidth: "0", float: "right" }}
                  className="cursor-pointer d-block border-radius mt-3 mr-4 p-2 float-right m0-auto m-0-auto button__ButtonOuter-gFbSyU"
                  onClick={() => {
                    close();
                  }}
                >
                  <X color={"#333"} strokeWidth={"3"} className="d-block" />
                </button>
                <div className="p-4 py-4 block">
                  <h2 className="mb-1 popupheading" style={{ lineHeight: "1.05" }}>
                    Contact Us
                  </h2>
                  <p
                    style={{
                      color: "#666",
                      lineHeight: "1.5",
                      fontSize: ".9em",
                    }}
                    className="mb-3 popupdesc"
                  >
                    Interested in learning more about our new home construction process? We're here to help. Simply give
                    us a call or fill out this form on our website and we'll take the time to learn all of the details
                    of your dream home.
                  </p>
                  <ContactForm
                    showServicesList={true}
                    showLabels={true}
                    showPlaceHolders={true}
                    pageLocation={"Not provided"}
                  />
                </div>
              </div>
            )}
          </Popup>
        );
      }
    },
  };


  const SidebarColumn = () => {
    const sideBarCards = pageData.sidebar.map((sidebar, index) => {
      if (sidebar.__typename == "ContentfulSidebar") {
        return <Card key={index} sidebar={sidebar} />;
      } else if (sidebar.__typename == "ContentfulSidebarCollection") {
        const innerCards = sidebar.sidebars.map((sidebar, index) => {
          return <Card key={"inner" + index} sidebar={sidebar} />;
        });
        return innerCards;
      } else return null;
    });

    return sideBarCards;
  };

  const FooterTestimonials = () => {
    const footerTestimonialComponent = pageData.sidebar.map((sidebar, index) => {
      if (sidebar.__typename == "ContentfulSidebar") {
        if (sidebar.content.content.includes("{{Testimonials}}")) {
          return <ReviewSliderFullWidth renderTestimonialsSidebar={sidebar} />;
        }
      } else if (sidebar.__typename == "ContentfulSidebarCollection") {
        const innerCards = sidebar.sidebars.map((sidebar, index) => {
          if (sidebar.content.content.includes("{{Testimonials}}")) {
            return <ReviewSliderFullWidth renderTestimonialsSidebar={sidebar} />;
          }
        });
        return innerCards;
      } else return null;
    });

    return footerTestimonialComponent;
  };

  const personalizedTextRef = useTextReplacement();

  return (
    <>
      <Layout pageProps={props} footerLogoShowcase={pageData.footerLogoShowcase} customSchema headerTransparent={true}>
        <SEO
          title={pageData.seoTitle}
          description={pageData.metaDescription}
          img={
            pageData.heroImage &&
            pageData.heroImage.gatsbyImageData &&
            pageData.heroImage.gatsbyImageData.images.fallback.src
          }
          keywords={pageData.metaKeywordsList ? pageData.metaKeywordsList : []}
          robots={pageData.metaRobotsContent}
        />

          <ServiceHeroFullBGService
            pageData={pageData}
            serviceTitle={pageData.serviceTitle}
            heroImage={pageData.heroImage}
            excerpt={props.pageContext?.subtitle || pageData.excerpt}
            offerDisclaimer={pageData.offerDisclaimer ? pageData.offerDisclaimer : ""}
            specialOffer={pageData.specialOffer}
            pageProps={props}
            badges={pageData.featuredBadges}
            subtitle={props.pageContext?.subtitle}
            headerTransparent
          />

          {pageData.serviceTitle.toUpperCase().includes("ROOF") &&   <ProductGalleryComponent/>}
      

        <ReviewTilesComponent />

        {pageData && pageData.showBeforeAfterPhotos ? (
          <div className="beforeaftersection">
            <div className="container flex flex-wrap justify-center">
              <div className="flex flex-col justify-center lg:w-1/3 lg:pr-4 w-full text-center lg:text-left lg:items-start">
                <p className="font-semibold m-0 p-0 text-gvd-500 text-lg">Before &amp; After Transformations</p>
                <h3 className="my-2 font-bold text-2xl leading-tight">
                  Check Out Our Work
                  {/* {pageData.serviceTitle} Before &amp; After */}
                </h3>
                <p className="text-neutral-600">{pageData.beforeAfterDescription}</p>
                {pageData.beforeafterPhotoGallery ? (
                  <Link
                    className="button-style-primary button-style-primary "
                    to={`/photo-gallery/${pageData.beforeafterPhotoGallery.slug}`}
                    state={{
                      modal: true,
                    }}
                  >
                    <span className="flex items-center justify-center babtn">
                      See More Before &amp; After Photos <ChevronRight />
                    </span>
                  </Link>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}

<div className="bg-gradient-to-br from-brand-50 to-white shadow">
        <TestimonialSection testimonial_video_posts={testimonial_video_posts} />

        <section className="max-w-7xl mx-auto px-4 md:px-6 py-20">
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/4">
              <p className="font-display text-5xl font-bold text-center">4.9/5</p>
              <div className={`flex items-center    justify-center py-3`}>
                {new Array(5).fill("").map((item) => (
                  <Star fill={"#f28d24"} stroke={"0"} size={18} />
                ))}
              </div>
              <h2 className="text-xl md:text-2xl font-display text-neutral-800 mb-2 font-extrabold text-center max-w-7xl mx-auto text-balance ">
                Hundreds of <span className="text-brand-600">Happy Customers</span>
              </h2>
              <p className=" mx-auto leading-relaxed  text-neutral-600 text-center max-w-4xl mx-auto py-2 text-sm md:text-base">
                Real reviews from real customers - see what they have to say about their experience with us.
              </p>

              <div className="mt-4 mx-auto flex justify-center border-t pt-4 hidden md:block">
                <Button color="brandGradient" size="big" className="w-full md:w-auto">
                  Request a FREE Estimate Now <ArrowRight size={18} />
                </Button>
              </div>
            </div>
            <div className="w-full md:w-3/4 md:pl-8">
              <Testimonials11 />
            </div>
          </div>
          <div className="mt-4 mx-auto flex justify-center border-t pt-4  md:hidden">
                <Button color="brandGradient" size="big" className="w-full md:w-auto">
                  Request a FREE Estimate Now <ArrowRight size={18} />
                </Button>
              </div>
        </section>
      </div>
        <div>
          <div className="flex flex-wrap  px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto py-8 md:py-12">
            <div className={`${pageData.sidebar ? "md:w-2/3 md:pr-4" : "w-full"} prose md:prose-lg`}>
              {pageData && pageData.content && pageData.content.content ? (
                <div ref={personalizedTextRef} dangerouslySetInnerHTML={{ __html: pageData.content.content }} />
              ) : (
                ""
              )}
            </div>

            {pageData.sidebar ? (
              <div className="w-full md:w-1/3 md:pl-4 space-y-4 md:space-y-6">
                <SidebarColumn />
                {props.data.serviceRelatedBlogPosts && props.data.serviceRelatedBlogPosts.edges.length > 0 && (
                  <Card
                    sidebar={{
                      title: "From Our Blog",
                      content: { content: "blog" },
                    }}
                  >
                    {props.data.serviceRelatedBlogPosts.edges.map(({ node }) => (
                      <BlogPostSideBarItem {...node} key={node.id} author={node.author.name} className="mb-3" />
                    ))}
                  </Card>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <WhyUsSection />
        <ProcessSection/>
        {pageData && pageData.photoGallery && pageData.photoGallery[0].photos ? (
          <PhotoGalleryComponent gallery={pageData.photoGallery} />
        ) : null}
        <FooterTestimonials />
        {showAreasServed && (
          <AreasServed
            citiesList={citiesList.edges}
            serviceName={pageData.serviceTitle}
            parentPageSlug={pageData.slug}
          />
        )}
        <FAQ classnames={'-mb-12'}/>
        <CTA
          title={"Start Your " + pageData.serviceTitle + " Project Today!"}
          customTitle={pageData.footerCallToActionHeading}
          customDesc={pageData.footerCallToActionDesc}
          bgimage={pageData.footerCallToActionImg}
          serviceHeroImage={pageData.heroImage}
          btn={"Get Started!"}
          props={props}
        />
      </Layout>
      <script
  type="application/ld+json"
  dangerouslySetInnerHTML={{
    __html: `
    {
      "@id": "https://top-tier-roofing.com#HomeAndConstructionBusiness",
      "@context": "https://www.schema.org",
      "@type": "HomeAndConstructionBusiness",
      "name": "Top Tier Roofing",
      "url": "https://top-tier-roofing.com/",
      "logo": "https://images.ctfassets.net/seqn6hyrh7p7/47ugz3TDJq3S0Vlkz6QjRt/04a537cf23f52f952ef3dfdc0e144663/roachhomeimprovement-thumb.jpg",
      "image": "https://images.ctfassets.net/seqn6hyrh7p7/47ugz3TDJq3S0Vlkz6QjRt/04a537cf23f52f952ef3dfdc0e144663/roachhomeimprovement-thumb.jpg",
      "description": "Top Tier Roofing is a ${pageData.serviceTitle
        .replace(/{{city}}/g, "")
        .replace(" in ", "")
        .replace(", CA", "")} specializing in roofing services near you.",
      "telephone": "+1 833-867-8137",
      "priceRange": "$$",
      "openingHours": "Mo, Tu, We, Th, Fr 08:00-18:00",
      "contactPoint": {
        "@type": "ContactPoint",
        "contactType": "sales",
        "telephone": "+1 833-867-8137"
      }
    }`,
  }}
/>
<script
  type="application/ld+json"
  dangerouslySetInnerHTML={{
    __html: `
    {
      "@context": "http://schema.org/",
      "@type": "Service",
      "serviceType": "${pageData.serviceTitle}",
      "provider": {
        "@type": "Organization",
        "name": "${props.data.site.siteMetadata.title}",
        "url": "${props.data.site.siteMetadata.siteUrl + props.location.pathname}",
        "logo": "https://images.ctfassets.net/seqn6hyrh7p7/47ugz3TDJq3S0Vlkz6QjRt/04a537cf23f52f952ef3dfdc0e144663/roachhomeimprovement-thumb.jpg",
        "image": "https://images.ctfassets.net/seqn6hyrh7p7/47ugz3TDJq3S0Vlkz6QjRt/04a537cf23f52f952ef3dfdc0e144663/roachhomeimprovement-thumb.jpg",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "1351 Washington St",
          "addressLocality": "Jefferson",
          "addressRegion": "GA",
          "postalCode": "30549"
        },
        "contactPoint": {
          "@type": "ContactPoint",
          "contactType": "customer support",
          "telephone": "${props.data.site.siteMetadata.companyInfo.phone}",
          "email": "${props.data.site.siteMetadata.companyInfo.email}"
        }
      },
      "areaServed": {
        "@type": "Place",
        "name": "Jefferson",
        "url": "https://en.wikipedia.org/wiki/Jefferson,_Georgia"
      },
      "hasOfferCatalog": {
        "@type": "OfferCatalog",
        "name": "${pageData.serviceTitle}",
        "itemListElement": [
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "roofing repair services"
            }
          }
        ]
      }
    }
    `,
  }}
/>
    </>
  );
};
const BlogPostSideBarItem = ({ title, slug, image, publishDate, author, page = "blog", excerpt, className }) => {
  return (
    <Link to={"/" + page + "/" + slug} className="px-4 flex mb-4 space-x-4" title={title}>
      <div className="w-24 h-24 flex-basis">
        <GatsbyImage image={getImage(image)} alt={title} className="rounded-lg" />
      </div>
      <div className="flex-1">
        <p className="font-display pb-2 block hover:text-brand-600 flex font-semibold items-top text-base mb-0 leading-tight text-custom-gray">
          {title}
        </p>
        <p className="text-sm text-neutral-500 mb-0">
          {publishDate} by {author}{" "}
        </p>
      </div>
    </Link>
  );
};

export default ServiceTemplate;

export const pageQuery = graphql`
  query serviceQuery($slug: String!, $serviceID: String, $categorySlug: String) {
    site {
      siteMetadata {
        title
        siteUrl
        companyInfo {
          phone
          email
        }
        social {
          guildquality
          facebook
          yelp
          instagram
          youtube
          google
          houzz
        }
      }
    }
    contentfulTestimonialCategory(service: { elemMatch: { id: { eq: $serviceID } } }) {
      id
    }
    contentfulService(slug: { eq: $slug }) {
      id
      serviceTitle
      seoTitle
      metaDescription
      metaKeywordsList
      metaRobotsContent
      slug
      excerpt
      offerDisclaimer
      sliderHeading
      sliderSubHeading
      specialOffer
      socialReviewsFullWidth {
        id
        slug
      }
      testimonialSection {
        testimonials {
          id
          title
          projectReference {
            slug
          }
          video {
            id
            title
            file {
              url
              contentType
            }
          }
          thumbnail {
            gatsbyImageData(width: 600, quality: 90, placeholder: NONE)
          }
        }
      }

      showFeaturedBenefitsSection
      beforeAfterDescription
      showBeforeAfterPhotos
      content {
        content
      }
      beforeafterPhotoGallery {
        slug
      }
      photoGallery {
        id
        title
        description {
          description
        }
        photos {
          id
          title
          gatsbyImageData(width: 150, quality: 70, aspectRatio: 1.26153846154, placeholder: NONE)
          fullSize: gatsbyImageData(width: 1874, quality: 70, placeholder: NONE)
        }
      }
      featuredBenefits {
        featuredBenefits
      }
      photoSlider {
        id
        photos {
          id
          gatsbyImageData(width: 220, quality: 80, placeholder: NONE)
          smallerFluid: gatsbyImageData(width: 220, quality: 80, placeholder: NONE)
          fluid: gatsbyImageData(width: 1000, quality: 100, placeholder: NONE)
        }
      }
      featuredBadges {
        title
        gatsbyImageData(width: 300, placeholder: NONE)
      }
      footerCallToActionHeading
      footerLogoShowcase
      footerCallToActionDesc
      footerCallToActionImg {
        gatsbyImageData(width: 1800, placeholder: NONE)
      }
      heroImage {
        gatsbyImageData(width: 1200, quality: 70, placeholder: BLURRED)
      }
      callToAction

      sidebar {
        __typename
        ... on ContentfulSidebar {
          id
          title
          content {
            id
            content
          }
          testimonialCategory {
            id
            name
          }
          cssClasses
        }
        ... on ContentfulSidebarCollection {
          sidebars {
            id
            title
            content {
              id
              content
            }
            testimonialCategory {
              id
              name
            }
            cssClasses
          }
        }
      }
      topbar {
        id
        title
        content {
          id
          content
        }
        cssClasses
      }
      showAreasServed
    }
    citiesList: allContentfulServiceArea(sort: { fields: location }) {
      edges {
        node {
          location
          slug
          marketSegment
          zipCodes
        }
      }
    }
    serviceRelatedBlogPosts: allContentfulBlogPost(
      filter: { category: { slug: { eq: $categorySlug } } }
      limit: 6
      sort: { fields: publishDate, order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          # tags
          image: heroImage {
            gatsbyImageData(
              height: 120
              width: 120
              aspectRatio: 1
              resizingBehavior: CROP
              cropFocus: CENTER
              quality: 70
              placeholder: NONE
            )
          }
          metaDescription
          author {
            name
          }
        }
      }
    }
  }
`;
